input,
select,
button {
   appearance: none;
   background-color: $black;
   border: 1px solid;
   border-radius: 2rem;
   box-sizing: border-box;
   color: inherit;
   font: inherit;
   margin: .25em 0 0;
   outline: none;
   padding: .33em .55em .175em;
}

button {
   cursor: pointer;
   font-size: 9px;
   padding: 3px 6px 1px;
   text-transform: uppercase;
}

::selection {
   background-color: transparent;
   // color: $white;
}
.guide-link {
   font-size: $s;
   left: 0;
   padding: 2rem 1rem 2rem 2rem;
   position: fixed;
   top: 50%;
   transform: translateY(-50%);
   z-index: 1;

   &:hover {
      text-decoration: underline;
   }
}

.guide {
   backdrop-filter: blur(4px) brightness(0.6);
   border: 1px solid;
   border-radius: .5rem;
   font-size: $s;
   left: 5rem;
   padding: 1rem;
   position: fixed;
   top: 50%;
   transform: translateY(-50%);
   transform-origin: 0 50%;
   transition: all 280ms $cubic;

   &.show {
      box-shadow: 0 0 4rem 2rem $black;
   }

   &:not(.show) {
      opacity: 0;
      pointer-events: none;
      transform: translateY(-50%) scale(0);
   }

   p {
      margin-left: 1em;
      max-width: 14rem;
      text-indent: -1em;
      transition: opacity 200ms;

      &::before {
         content: "•";
         display: inline-block;
         text-indent: 0;
         width: 1em;
      }

      &.ok {
         opacity: .5;
         text-decoration: line-through;
      }
   }
}

.lit {
   .guide {
      backdrop-filter: blur(4px) brightness(1.25);
      color: $black;

      &.show {
         box-shadow: 0 0 4rem 2rem $white;
      }
   }
}
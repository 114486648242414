body {
   &::before {
      animation: spin 2s infinite linear;
      border: 6px dotted;
      border-radius: 100em;
      content: "";
      height: 1rem;
      left: calc(50% - .5rem - 4px);
      opacity: 0;
      position: fixed;
      top: calc(50% - .5rem - 6px);
      transform: scale(0);
      transition: all 500ms $long;
      width: 1rem;
      z-index: 999;
   }
   
   &:not(.rdy),
   &.instant {
      main {
         opacity: 0 !important;
         transition: none !important;
      }
   }
}

body.blur {
   pointer-events: none;

   .boxes {
      // filter: blur(50px);
      // opacity: .5;
      pointer-events: none;
   }

   &::before {
      opacity: 1;
      transform: scale(1);
   }
   
   .minimap-container,
   .footer,
   .log,
   main {
      opacity: .1;
      pointer-events: none;
   }
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

.loading {
   // display: none;
   font-size: $s;
   left: calc(50% + .5rem);
   position: fixed;
   text-align: center;
   top: calc(50% + 2rem);
   transform: translate3d(-50%, -50%, 10px);
   white-space: nowrap;
   
   i {
      display: inline-block;
      text-align: left;
      width: 1em;
   }
}

.clear-cache {
   bottom: 1.5rem;
   cursor: pointer;
   font-size: $s * .75;
   position: fixed;
   right: 1.5rem;
   text-transform: uppercase;

   &:hover {
      opacity: .6;
   }
}

main {
   inset: 0;
   // perspective: 2000px;
   position: absolute;
   transform: scale(.8);
   // transform-origin: 0 0;
   transform-style: preserve-3d;
   // transition: perspective 2s;
   will-change: transform;
   // width: 250vw;

   &.box-open {
      // perspective: 6000px;
   }

   &.relating {
      // perspective: 6000px;

      &:not(.mousedown) {
         transition: transform 300ms $cubic;
      }

      .box:not(.related, .hovering, .open) {
         opacity: .2 !important;
         transition: opacity 300ms;
         
         &:hover {
            opacity: 1 !important;
         }
      }
   }

   &.box-open:not(.mousedown) {
      transition: transform 300ms $cubic, opacity 400ms $cubic;
   }

   &:not(.mousedown) {
      transition: transform 500ms $cubic, opacity 400ms $cubic;
      // transition: perspective-origin 40ms, transform 300ms $cubic;
   }

   &.mousedown {
      transition: all 110ms linear, transform none, opacity 400ms $cubic;
   }

   &.mousedown .boxes {
      &, * { cursor: grabbing; }

      .box {
         &:hover h3 {
            text-decoration: none;
         }
      }
   }

   &.zooming-in .boxes {
      &, * { cursor: zoom-in; }
   }

   &.zooming-out .boxes {
      &, * { cursor: zoom-out; }
   }
}
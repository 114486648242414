.box-open ~ .footer {
   transform: translate3d(-50%, 100%, 0);
   transition-delay: 0ms;
}

.footer {
   align-items: flex-end;
   backdrop-filter: blur(4px) brightness(0.6);
   // background-color: rgb(0 0 0 / 90%);
   border: 1px solid;
   border-radius: .5rem;
   bottom: .25rem;
   // box-shadow: 0 0 2em 1em black;
   box-sizing: border-box;
   display: flex;
   font-size: $s;
   gap: 1rem;
   justify-content: center;
   left: 50%;
   // overflow: hidden;
   padding: 0.5rem;
   position: fixed;
   transform: translateX(-50%);
   transition: transform 1200ms 400ms $long;

   @media (width <= 1180px) {
      font-size: 1vw;
      // gap: 1%;
   }

   & > label {
      @include tiny;

      font-size: .75em;
      left: .55rem;
      position: absolute;
      top: .5rem;
   }

   &:not(.in) {
      transform: translate3d(-50%, 120%, 0);
      transition: transform 900ms 0ms $cubic;
   }

   &--view,
   &--cats,
   &--search,
   &--filter {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // text-shadow: 
      //    0 0 12px $black,
      //    0 0 16px $black,
      //    0 0 16px $black,
      //    0 0 10px $black,
      //    0 0 10px $black;

      label {
         cursor: pointer;
      }

      input {
         // box-shadow: -1px 0 10px 3px $black;

         &[type="text"] {
            font-size: $s;
         }

         &::placeholder {
            color: #7b7b7b;
            font-size: $s;
            -webkit-font-smoothing: antialiased;
            font-weight: normal;
         }
      }

      & > label:not(.hyper) {
         @include tiny;

         display: none;
         font-size: .75em;
         letter-spacing: 0.05em;
         line-height: 1.1;
      }
   }

   .hyper {
      margin-bottom: 6px;

      &:hover {
         span {
            text-decoration: underline #c9e4ff;
         }
      }

      span {
         background: linear-gradient(90deg, #fac8c8, #c9e4ff);
         background-clip: text;
         color: transparent;
      }

      input[type="checkbox"] {
         height: 7px;
         margin-left: -1px;
         margin-right: 2px;
         padding: 0;
         width: 7px;
         
         &:checked {
            background: white;
         }
      }
   }

   .toggle-unimportant {
      &::before {
         content: "☝";
      }

      &.on {
         &::before {
            content: "🖐";
         }
      }
   }

   nav.cats {
      height: 3.525em;
      width: 5.5em;
   }
   
   .stacked {
      display: flex;
      flex-direction: column;
      gap: 1.6px 0;
      justify-content: flex-end;
      position: relative;
      text-align: left;
      white-space: nowrap;
      z-index: 10;
      
      label {
         input[type="checkbox"],
         input[type="radio"] {
            height: 7px;
            margin-left: -1px;
            margin-right: 2px;
            padding: 0;
            width: 7px;
            
            &:checked {
               background: white;
            }
         }
   
         &:hover {
            span {
               text-decoration: underline;
            }
         }
      }

      &.theme-select {
         display: grid;
         // grid-template-columns: auto auto;
      }
   
      /*
      a {
         display: inline-block;
         font-size: 1rem;
         padding: .5rem 1rem;
         position: absolute;
         transition: all 200ms;
   
         &.cur {
            text-decoration: underline;
         }
   
         &:has(~ .cur) {
            transform: translateY(-3em) rotateX(30deg) translateZ(-5px);
         }
   
         &:has(+ .cur) {
            transform: translateY(-1.75em) rotateX(20deg) translateZ(-5px);
   
            & + .cur + a {
               transform: translateY(1.8em) rotateX(-20deg) translateZ(-5px);
            }
         }
   
         &.cur {
            & ~ *:nth-child(2) {
               transform: translateY(1.75em) rotateX(-20deg) translateZ(-5px);
            }
   
            & ~ *:nth-child(3) {
               transform: translateY(3em) rotateX(-30deg) translateZ(-5px);
            }
         }
      }
      */
   }

   .reset {
      margin-left: -.5rem;
   }

   [data-theme="AI and Data"] {
      display: none;
   }
}
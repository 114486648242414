.bib-link {
   bottom: 0;
   font-size: $s;
   padding: 1.5rem 2rem;
   position: fixed;
   right: 0;
   transition: all 300ms;
   white-space: nowrap;
   z-index: 3;

   &:hover {
      text-decoration: underline;
   }

   &::before {
      animation: spin 1.4s infinite;
      border: 4px dotted;
      border-radius: 10em;
      box-sizing: border-box;
      content: "";
      display: block;
      height: 1rem;
      left: 0.5rem;
      opacity: 0;
      position: absolute;
      top: 1.33rem;
      width: 1rem;
   }

   &.disabled {
      opacity: 0.2;
      pointer-events: none;

      &::before {
         opacity: 1;
      }
   }
}

.bib {
   // backdrop-filter: blur(3px);
   background-color: rgb(0 0 0 / 90%);
   box-sizing: border-box;
   inset: 0;
   opacity: 0;
   overflow: auto;
   overflow-x: hidden;
   padding: 0 4px 8rem;
   pointer-events: none;
   position: fixed;
   transform: translateY(4rem);
   transition: all 1000ms $long;
   z-index: 1;

   &-wrap {
      margin-left: auto;
      margin-right: auto;
      max-width: 1080px;

      &::before {
         content: "Bibliography";
         display: block;
         font-size: 1rem;
         margin-bottom: 1rem;
         margin-top: 4rem;
         position: relative;
         z-index: 3;
      }
   }

   &--close {
      background: linear-gradient(45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px)), linear-gradient(-45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px));
      cursor: pointer;
      display: block;
      float: right;
      font-size: 1rem;
      height: 3rem;
      position: sticky;
      right: 1rem;
      top: 2rem;
      transition: none;
      width: 3rem;
      z-index: 2;
   }
   
   &--header {
      align-items: center;
      background-color: transparentize($black, .1);
      border: 1px solid;
      box-shadow: 0 -1rem 6em 2.5em $black;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 6rem 6rem calc(50% - 2rem) 1fr;
      height: 2.25rem;
      padding-top: 2px;
      position: sticky;
      top: .5rem;
      transform: translateX(-1rem);
      width: calc(100% - 7rem);
      z-index: 2;

      span {
         cursor: pointer;
         font-size: $xs;
         letter-spacing: 0.03em;
         padding-bottom: 1em;
         padding-top: 1em;
         text-transform: uppercase;

         &:hover {
            opacity: .75;
         }

         &:first-child {
            padding-left: 1rem;

            &:hover {
               cursor: default;
               opacity: 1;
            }
         }

         &.cur {
            &::before {
               // straight arrow down
               content: "↑";
               margin-right: 0.5rem;
            }

            &.asc {
               &::before {
                  content: "↓";
               }
            }
         }
      }
   }
   
   &--content {
      // background: linear-gradient(0deg, #fac8c8, #c9e4ff);
      // background-clip: text;
      // color: transparent;
      box-shadow: 0 0 20rem 10rem $black;
      line-height: 1.375;
      padding-top: 2rem;
      width: 100%;

      &--entry {
         cursor: pointer;
         display: grid;
         grid-template-columns: 5rem 6rem 1fr 1fr;
         transition: color 60ms;
         width: 100%;

         .id {
            font-size: 50%;
            letter-spacing: 0.03em;
            line-height: 1.2;
            margin-bottom: .25em;

            &::before {
               content: "[#";
            }

            &::after {
               content: "]";
            }
         }

         &:hover {
            color: $red;
            height: auto;
            z-index: 1;

            .names,
            .title {
               background-color: $black;
               // box-shadow: 0 0 2.5rem 1rem $black;
               // margin: -.25rem -1rem;
               // padding: .25rem 1rem;
               white-space: normal;
               z-index: 1;
            }
         }

         .title,
         .names {
            grid-column: 3;
            overflow: hidden;
            position: absolute;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(50% - 1em);
         }
      
         .names {
            grid-column: 4;
            width: calc(100% - 1em);
         }
      }
   }
}

.bib-open {
   .bib {
      opacity: 1;
      pointer-events: auto;
      transform: none;
      z-index: 2;

      &-link {
         &::after {
            content: "X";
            position: absolute;
            right: calc(100% - 1.75rem);
         }
      }
   }
}
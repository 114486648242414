.about-link {
   font-size: .75rem;
   padding: 1.33rem 2rem;
   position: fixed;
   right: 0;
   top: 0;
   white-space: nowrap;
   z-index: 1;

   &:hover {
      text-decoration: underline;
   }

   &::before {
      content: "X";
      position: absolute;
      right: calc(100% - 1.75rem);
   }
}

.about-text {
   background-color: rgb(0 0 0 / 90%);
   inset: 0;
   line-height: 1.375;
   // line-height: 1.66;
   overflow: auto;
   padding: 0;
   position: fixed;
   transition: all 1500ms $long;
   z-index: 1;

   &--inner {
      background: linear-gradient(0deg, #fac8c8, #c9e4ff);
      background-clip: text;
      box-shadow: 0 0 20rem 10rem $black;
      box-sizing: border-box;
      color: transparent;
      display: block;
      // font-size: $m;
      margin: 0 auto;
      max-width: 40em;
      padding: 0 0 20vh;
      position: relative;

      &::before {
         background: rgba(0 0 0 / 95%);
         box-shadow: 0 0 20rem 10rem $black;
         content: "";
         inset: 0;
         position: absolute;
         z-index: -1;
      }
      
      p:not(:last-child) {
         margin-bottom: 0;
      }

      .title {
         border-bottom: 1px solid #c9e4ff;
         // content: "About";
         display: block;
         font-size: 1rem;
         margin-bottom: 4rem;
         margin-top: 4rem;
         // position: relative;
         // z-index: 3;
      }
   }
   
   .spacer {
      display: block;
      height: 2.5em;
   }
   
   ::selection {
      background-color: #333;
   }
   
   .copy {
      font-size: $s;
      margin-bottom: .25em;
   }

   .about--close {
      background: linear-gradient(45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px)), linear-gradient(-45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px));
      cursor: pointer;
      display: block;
      float: right;
      font-size: 1rem;
      height: 3rem;
      position: sticky;
      right: calc(50% - 540px);
      top: 6rem;
      transition: none;
      width: 3rem;
      z-index: 2;
   }
}

body:not(.about-open) {
   .about-text {
      opacity: 0;
      pointer-events: none;
      transform: translateY(4rem);
   }

   .about-link {
      &::before {
         display: none;
      }
   }
}

.about-open {
   .about-text {
      z-index: 4;
   }

   .about-link {
      z-index: 4;
   }
}
@import 'general/vars';
@import 'general/reset';
@import 'general/fonts';
@import 'general/typography';
@import 'general/ux';
@import 'pages/home';
@import 'pages/about';
@import 'parts/entries';
@import 'parts/entry';
@import 'parts/footer';
@import 'parts/log';
@import 'parts/minimap';
@import 'parts/bib';
@import 'parts/tooltip';
@import 'parts/guide';

html {
  background-color: $black;
  color: white;
  transition: opacity 400ms, background-color 400ms;
}

:root {
  // height: 100%;
  // touch-action: pan-x pan-y;
}

body {
  height: 100vh;
  overflow: hidden;
  transition: opacity 400ms, background-color 400ms;
  width: 100vw;

  @media ($mob) {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  p {
    &:not(:last-child){
      margin-bottom: 1em;
    }
  }
}

a[href="#lit"] {
  font-size:.75rem;
  left:0;
  padding:1rem 2rem;
  position:fixed;
  top:0;
  transition: top 1500ms $lazy;
  z-index:1;

  &:has(~ .log.in) {
    top: 3rem;
  }
}

html.lit-anim {
  &.to-black {
    background-color: $black;

    body { opacity: 0; }
  }

  &.to-white {
    background-color: $white;

    body { opacity: 0; }
  }
}

html.lit {
  background-color: $white;
  color: $black;
  
  a[href="#lit"] {
    filter: invert(1);
  }

  #minimap {
    border-color: #666;
    filter: invert(1);
    // background: #eee;
    // box-shadow: 0 0 2em .5rem #fff;
  }

  .box,
  .box.related {
    background: $white;
    border-color: $black !important;

    &:hover {
      text-shadow: none;
    }

    &.has-rel:not(.no-rel)::after {
      background-color: $blue;
    }

    .close {
      filter: invert(1);
    }
  }

  #lines-canvas {
    background: $white;
  }

  .log--title {
    background: $white;
    box-shadow: 0 0.6rem 0.2rem $white;
  }

  .footer {
    backdrop-filter: blur(4px) brightness(1.25);

    label {
      text-shadow: none;
    }
  }

  .log,
  input, select, button {
    background-color: $white;
    border-color: $black;
    color: $black;
  }

  .log {
    box-shadow: 0 0 2rem 1rem #fff;

    &-wrap {
      &::before {
        background: linear-gradient(45deg, transparent calc(100% - 5rem), white calc(100% - 2rem));   
      }
    }

    &--item {
      &,
      &:hover {
        color: $black;
      }

      em {
        background: linear-gradient(45deg, transparent calc(50% - 1px), $black 50%, transparent calc(50% + 1px)), linear-gradient(-45deg, transparent calc(50% - 1px), $black 50%, transparent calc(50% + 1px))
      }
    }
  }

  nav.cats label input[type="checkbox"]:checked {
    // background-color: $black;
    background: linear-gradient(90deg, transparent calc(50% - 0px), $black 50%, transparent calc(50% + 1px))
  }

  .bib {
    background-color: rgb(255 255 255 / 90%);
    
    &--header {
      background-color: rgb(255 255 255 / 90%);
      box-shadow: 0 -1rem 6em 2.5em #fff;
    }

    &--content {
      box-shadow: 0 0 20rem 10rem #fff;
    }

    &--close {
      filter: invert(1);
    }
  }
}
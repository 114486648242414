$boxDist: 500px;
$basicTransform: translate3d(-50%, -50%, 0); // rotateX(2deg) scaleY(0.95);

$flyDelay: 0ms;
$flyBezier: cubic-bezier(0.02, 0.94, 0.58, 1);
$flyDur: 1100ms;
$fly: $flyDur $flyDelay $flyBezier;

.box-backdrop {
   // inset: 0;
   // background: $black;
   cursor: zoom-out;
   height: 2000vh;
   left: 50%;
   opacity: 0;
   pointer-events: none;
   position: fixed;
   top: 50%;
   transform: translate3d(-50%, -50%, 0);
   transition: opacity 800ms;
   width: 2000vw;
   z-index: 0;

}

.relating .act .box-backdrop {
   opacity: 0;
   pointer-events: auto;
}

.box-open .act .box-backdrop {
   opacity: 0;
   pointer-events: auto;
}

#lines-canvas {
   // background: black;
   // clip-path: polygon(
   //    0 0,
   //    0 100%,
   //    100% 100%,
   //    100% 0
   // );
   // height: 100%;
   pointer-events: none;
   // transition: opacity 1000ms;
   // width: 100%;
   // will-change: opacity, width, height;

   &:not(.in) {
      // opacity: 0;
   }

   &.out {
      // opacity: 0;
      // clip-path: polygon(
      //    0 0,
      //    0 100%,
      //    0% 100%,
      //    0% 0
      // );
   }
}

main {
   // perspective: 100px;
   // perspective-origin: inherit;
   // transform-style: preserve-3d;
}

.box-open .boxes {
   min-height: 0;
}

.boxes {
   align-items: center;
   box-sizing: border-box;
   // display: flex;
   // flex-wrap: wrap;
   cursor: grab;
   gap: 1em;
   // justify-content: center;
   left: 50%;
   min-height: 90vh;
   // max-height: calc(100vh - 8rem);
   // max-width: 1200px;
   // margin: -1em;
   // overflow: visible;
   // overflow: auto;
   // padding: 8rem 4rem;
   position: absolute;
   top: 50%;
   transform: $basicTransform ; // rotate3d(3.4, -0.1, 0, $deg);
   transform-origin: 50% 50%;
   transform-style: preserve-3d;
   transition: transform 600ms $cubic, filter 600ms $cubic;
   width: 200vh;
   
   &.act {
      & ~ .boxes {
         pointer-events: none;
         
         & > .box {
            opacity: 0.25;
            // opacity: 0.25;
         }
      }
   }

   &:not(.act) {
      pointer-events: none;
   }

   &-inner {
      left: 50%;
      // opacity: 0;
      // min-width: 250vw;
      min-width: 7000px;
      transform: translateX(-50%);
      transform-style: preserve-3d;
      transition: opacity 500ms 200ms;

      &.filtered {
         min-height: 100vh;
         min-width: 100vw;
         padding-top: 50vh;

         .box {
            margin: 0 2rem 2rem 0 !important;
            transition-delay: 0ms !important;

            &.related,
            &.hovering { 
               display: block !important;
               transform: none !important;
               
               &.open {
                  display: grid !important;
               }
            }

            &:not(.has-image) {
               figure {
                  display: none;
               }
            }
         }

         figcaption {
            display: none;
         }
      }
   }

   &[data-view-as="image"] {
      .boxes-inner {
         &:not(.filtered) {
            .box:not(.open) {
               &:not(.has-image) {
                  display: none;
                  opacity: 0;
                  pointer-events: none;
                  position: absolute;
                  
                  &.related-fly {
                     transform: none !important;
                     transition: 
                     left $flyDur $flyBezier,
                     top $flyDur $flyBezier;
                  }
               }
            }
         }

         .box:not(.open) {
            &.has-image {
               border: 0;
               max-height: none;
               max-width: 15em;
               // min-width: 20em;
               padding: 0 0 2px;
               
               .title,
               .box-meta {
                  opacity: 0;
                  pointer-events: none;
                  transition: opacity 200ms 200ms;
               }
               
               .title {
                  bottom: calc(100% - .5rem);
                  display: block;
                  font-size: 1rem;
                  min-height: 0;
                  position: absolute;
                  text-decoration: none;
               }
               
               .box-meta {
                  display: flex;
                  font-size: $s;
                  line-height: 1.25;
                  position: absolute;
                  top: 100%;
                  width: 100%;
               }
               
               .status {
                  display: none;
               }
               
               figure {
                  grid-column: 1 / span 2;
                  justify-self: stretch;
                  max-height: none;
                  width: auto;
                  
                  img {
                     height: auto;
                     max-height: 20em;
                     object-fit: contain;
                  }
                  
                  figcaption {
                     background-color: black;
                     box-shadow: 1rem 0 2em 0.5em black;
                     font-size: $s;
                     left: 100%;
                     min-width: 12em;
                     opacity: 0;
                     padding: .5rem;
                     pointer-events: none;
                     position: absolute;
                     top: -.5rem;
                     transition: opacity 900ms 500ms;
                  }
                  
                  &:hover {
                     figcaption {
                        opacity: 1;
                        pointer-events: auto;
                     }
                  }
               }
               
               &.hovering,
               &:hover {
                  z-index: 2;
                  
                  .title,
                  .box-meta {
                     opacity: 1;
                  }
               }
            }
            
            &.related-fly {
               opacity: 1;
               z-index: 2;
               // transition:
               //    border-color $fly,
               //    top $fly,
               //    left $fly,
               //    transform 400ms,
               //    opacity 400ms 10ms;
               
               &:not(.related) {
                  pointer-events: none !important;
               }
            }
         }
      }
   }

   &[data-view-as="text"] {
      .boxes-inner {
         .box:not(.open) {
            figure {
               display: none;
            }

            .title {
               grid-column: 1 / span 2;
               margin-bottom: 0;
               margin-right: 0;
               width: 100%;
            }
         }
      }
   }
}

main {
   &.nth-act-1 {
      .boxes:nth-child(1) {
         opacity: 1;
      }

      .boxes:nth-child(2) { 
         transform: $basicTransform translateZ($boxDist*1);
         // transform: translate3d(-50%, ($stackHeight * 4), ($boxDist*1)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.1; 
         }
      }

      .boxes:nth-child(3) { 
         transform: $basicTransform translateZ($boxDist*2);
         // transform: translate3d(-50%, ($stackHeight * 5.5), ($boxDist*3)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.1;
         }
      }

      .boxes:nth-child(4) { 
         transform: $basicTransform translateZ($boxDist*3);
         // transform: translate3d(-50%, ($stackHeight * 7), ($boxDist*6)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.1;
         }
      }
   }
   
   &.nth-act-2 {
      .boxes:nth-child(1) { 
         transform: $basicTransform translateZ($boxDist*-1);
         // transform: translate3d(-50%, ($stackHeight * 1), ($boxDist*-3)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.5;
         }
      }
      .boxes:nth-child(2) { opacity: 1; }

      .boxes:nth-child(3) { 
         transform: $basicTransform translateZ($boxDist*1);
         // transform: translate3d(-50%, ($stackHeight * 4), ($boxDist*1)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.1;
         }
      }

      .boxes:nth-child(4) { 
         transform: $basicTransform translateZ($boxDist*2);
         // transform: translate3d(-50%, ($stackHeight * 5.5), ($boxDist*3)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.1;
         }
      }
   }
   
   &.nth-act-3 {
      .boxes:nth-child(1) { 
         transform: $basicTransform translateZ($boxDist*-2);
         // transform: translate3d(-50%, ($stackHeight * -1.5), ($boxDist*-6)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.25;
         }
      }

      .boxes:nth-child(2) { 
         transform: $basicTransform translateZ($boxDist*-1);
         // transform: translate3d(-50%, ($stackHeight * 1), ($boxDist*-3)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.5;
         }
      }
      .boxes:nth-child(3) { opacity: 1; }

      .boxes:nth-child(4) { 
         transform: $basicTransform translateZ($boxDist*1);
         // transform: translate3d(-50%, ($stackHeight * 4), ($boxDist*1)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0;
         }
      }
   }
   
   &.nth-act-4 {
      .boxes:nth-child(1) { 
         transform: $basicTransform translateZ($boxDist*-3);
         // transform: translate3d(-50%, ($stackHeight * -3), ($boxDist*-12)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0;
         }
      }

      .boxes:nth-child(2) { 
         transform: $basicTransform translateZ($boxDist*-2);
         // transform: translate3d(-50%, ($stackHeight * -1.5), ($boxDist*-6)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.25;
         }
      }

      .boxes:nth-child(3) { 
         transform: $basicTransform translateZ($boxDist*-1);
         // transform: translate3d(-50%, ($stackHeight * 1), ($boxDist*-3)) rotate3d(3.4, -0.1, 0, $deg);
         .box {
            opacity: 0.5;
         }
      }
      .boxes:nth-child(4) { opacity: 1; }
   }
}


.box[data-relateds="1"]:not(.open) {
   // transform: translateZ(0.2px);
}

.box[data-relateds="1"]:not(.open),
.box[data-relateds="2"]:not(.open),
.box[data-relateds="3"]:not(.open) {
    max-width: 8em !important;
    min-width: 8em;
   //  transform: translateZ(-4px);

    .title {
      font-size: 1rem;
    }

    .box-meta {
      text-align: left;
      white-space: normal;
  }
}

.box[data-relateds="4"]:not(.open),
.box[data-relateds="5"]:not(.open),
.box[data-relateds="6"]:not(.open),
.box[data-relateds="7"]:not(.open) {
    max-width: 12em !important;
    min-width: 12em;
   //  transform: translateZ(2px);

    .box-meta {
      text-align: left;
      white-space: normal;
  }
}

.box[data-relateds="6"]:not(.open),
.box[data-relateds="7"]:not(.open) {
   // transform: translateZ(3px);
}

.box[data-relateds="8"]:not(.open),
.box[data-relateds="9"]:not(.open),
.box[data-relateds="10"]:not(.open),
.box[data-relateds="11"]:not(.open),
.box[data-relateds="12"]:not(.open),
.box[data-relateds="13"]:not(.open) {
   &:not([style*="display: none"]){
      display: flex;
   }

   flex-direction: column;
   justify-content: space-between;
   margin-top: 0 !important;
   min-height: 16em;
   min-width: 16em;
   // transform: translateZ(10px);
   
   figure,
   .videothumb {
      align-items: stretch; 
      display: flex;
      flex: 1;
      height: auto;
      justify-content: stretch;
      margin-bottom: 1rem;
      max-height: 5rem;
      
      img {
         max-height: 100% !important;
         min-height: 7rem;
         min-width: 7rem;
         object-fit: cover !important;
      }
   }
}

.lit {
   .box-backdrop {
      // background-color: white;
   }
}
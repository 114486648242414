.tooltip {
   backdrop-filter: blur(4px);
   background: rgb(255 255 255 / 5%);
   border-radius: .5rem;
   color: rgb(255 255 255 / 70%);
   font-size: $xs;
   left: 0;
   letter-spacing: 0.03em;
   opacity: 0;
   padding: .5rem .75rem;
   pointer-events: none;
   position: fixed;
   top: 0.5rem;
   transition: top 300ms ease-in-out, opacity 300ms ease-in-out;

   &.active {
      opacity: 1;
      top: 0;
   }
}
.minimap-container {
   bottom: 0;
   left: 0;
   padding: 0 .25rem;
   position: fixed;
   transform: translateX(-110%);
   transition: all 1400ms $long;
   z-index: 1;

   @media (width >= 1440px) {
      bottom: .5rem;
      left: .5rem;
   }

   &,
   #minimap {
      cursor: pointer;
   }

   #minimap {
      background: black;
      border: 2px;
      border-style: outset;
      box-shadow: 0 0 2em .5rem $black;
      padding: .25rem;    
      transition: all 1000ms $long;
      width: 150px;

      @media (width <= 1180px) {
         width: 12vw;
      }

      &.filt {
         width: auto !important;
      }
   }
   
   @media (width <= 1220px) {
      // bottom: 4rem;

      #minimap {
         // width: 70%;
      }
   }
}

.rdy {
   .minimap-container {
      transform: translateX(0);
   }
}
html,
body {
   font-family: $sans;
   font-size: 16px;
   -webkit-font-smoothing: antialiased;
   // letter-spacing: .03em;
   line-height: 1.1;

   a {
      color: inherit;
      text-decoration: none;
   }

   * {
      font-family: inherit;
   }

   input {
      color: inherit;
      font-size: inherit;
   }
}

sup {
   font-size: .66em;
   vertical-align: 0.4em;
}
.log {
   background-color: $black;
   border: 1px solid;
   box-shadow: 0 0 3em 1em $black;
   font-size: $s;
   // height: 2.25rem;
   left: .25rem;
   overflow: hidden;
   position: fixed;
   right: 6rem;
   text-transform: uppercase;
   top: .25rem;
   transition: transform 1500ms $lazy;
   white-space: nowrap;

   &:not(.in) {
      transform: translateY(-130%);
   }

   &-wrap {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      // height: 3.35rem;
      justify-content: flex-start;
      overflow: auto;
      overflow-y: hidden;
      padding: 0.25rem 2rem 0.25rem 0;
      scrollbar-width: none;
      width: 100%;

      &::before {
         background: linear-gradient(45deg, transparent calc(100% - 5rem), black calc(100% - 2rem));
         content: "";
         inset: calc(0.5rem + 1px) calc(6rem + 1px) calc(100% - 2.5rem) calc(0.5rem + 1px);
         pointer-events: none;
         position: fixed;
         z-index: 1;
      }
   }

   &--title {
      background-color: $black;
      box-shadow: 0 0 0.6rem 0.2rem $black;
      left: 0;
      margin-right: .5rem;
      padding: .5rem .25rem .5rem .75rem;
      position: sticky;
   }

   &--content {
      align-items: center;
      display: inline-flex;
      white-space: nowrap;
      // padding-right: 2rem;

      .log--item {
         color: transparentize($white, .5);
         cursor: pointer;
         display: inline-block;
         height: auto !important;
         // border: 1px solid;
         // padding: 3px 5px 2px;
         // border-radius: 3px;
         line-height: 1;
         // margin-left: 1em;
         // opacity: 1 !important;
         transition: margin 800ms, color 200ms;

         &:hover {
            color: $white;

            &::after {
            color: transparentize($white, .5);
            }
         }
            
         &::before {
            content: #{attr(data-idx)} ' ';
         }

         &:first-child {
            // margin-left: .5;
         }

         &:last-child {
            padding-right: 3rem;
         }

         &:not(:last-child) {
            &::after {
               content: "—";
               padding: 0 .5em;
               transition: color 200ms 50ms;
            }
         }

         em {
            background: linear-gradient(45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px)), linear-gradient(-45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px));
            display: inline-block;
            height: .7em;
            margin-left: 0;
            max-width: 0;
            transition: all 200ms;
            width: .7em;
         }

         &:hover {
            em {
               margin-left: .5em;
               max-width: .7em;

               &:hover {
                  opacity: .5;
               }
            }
         }
      }
   }
   
   button.log--download {
      line-height: 1;
      margin: auto .5rem auto auto;
      padding: 4px 8px;
      position: absolute;
      right: 0;
      transition: all 300ms;
      z-index: 2;

      &:hover {
         background-color: $white;
         border-color: $white;
         color: $black;
      }
   }

   &--pages {
      background: white;
      border: 1px solid white;
      // display: none;
      // inset: 2rem;
      overflow: auto;
      // padding: 2rem;
      // position: fixed;
      
      &.show {
         display: block;
      }
      
      .box {
         background-color: white;
         border-color: black;
         border-radius: 4px;
         color: black;
         font-size: 16px;
         margin-bottom: 2rem !important;
         margin-left: 1rem !important;
         max-height: none;
         max-width: none;
         width: calc(100% - 2rem) !important;
         
         &::after {
            content: none !important;
         }
         
         h3.title {
            font-size: 150%;
            margin-bottom: -1rem;
         }

         &-content {
            display: block !important;
         }
         
         &--aside {
            font-size: 14px;
            
            .links a {
               box-sizing: border-box;
               overflow: visible;
               overflow: hidden;
               padding-right: 1rem;
               text-overflow: unset;
               white-space: normal;
               word-break: break-all;
            }
         }
      }
   }
}

body.printing {
   pointer-events: none;

   main {
      opacity: .1;
      transition: opacity 1200ms;
   }
}
// $flyBezier: $cubic;
$flyBezier: cubic-bezier(0.02, 0.94, 0.58, 1);
// $flyBezier: cubic-bezier(0.2, 1.12, 0.52, 0.97);
// $flyBezier: ease-out;
$flyDelay: 0ms;
$flyDur: 1100ms;
$fly: $flyDur $flyDelay $flyBezier;

.box {
   display: grid;
   gap: 1rem;
   grid-template-columns: 1fr 33.33%;
   transform: translateZ(0.1px);

   &:not(.open) {
      grid-template-columns: 1fr 3rem;
   }
   
   h3.title {
      -webkit-font-smoothing: subpixel-antialiased;
      grid-column: 1 / span 2;
      grid-row: 1;
      letter-spacing: 0.015em;
   }
   
   &-meta {
      grid-column: 1 / span 2;
      grid-row: 2;
   }

   svg {
      left: 0;
      position: absolute;
      top: 0;
   }
}

.box {
   background-color: $black;
   border: 1px solid $white;
   border-radius: 4px;
   box-sizing: border-box;
   cursor: default;
   max-height: 16em;
   max-width: 16em;
   min-width: 12em;
   padding: 1rem;
   // padding: 1.25em 2em 1.25em 1.25em;
   position: relative;
   // text-shadow: 1px 1px 4px $black;
   transform-origin: 50% 50%;
   // transform-style: preserve-3d;
   transition: opacity 400ms;
   will-change: top, left, transform, width, max-height, opacity;
   // transition: 
   //    // all 200ms,
   //    filter $openDur, 
   //    background-color 60ms, 
   //    color 60ms, 
   //    // border-color $openDur, 
   //    opacity $openDur*1.33 $cubic, 
   //    transform $openDur $cubic,
   //    width $openDur $cubic,
   //    max-height $openDur $cubic,
   //    left $flyDur $flyBezier,
   //    top $flyDur $flyBezier;
   // transition: filter 200ms, background-color 60ms, color 60ms, border-color $openDur, opacity 400ms $cubic;

   &.unimportant {
      &:not(.open) {
         border-color: $brown;
         display: none;
         transform: scale(0);

         &.show {
            transform: scale(1);
         }
      }
   }

   &.no-rel {
      border-color: #474747 !important;

      .status {
         opacity: 0.33;
      }
   }

   &.has-rel:not(.no-rel)::after {
      background: $blue;
      bottom: 3px;
      content: '';
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      transition: width 500ms ease-out;
      width: 0;
      z-index: 1;
   }
   
   &.has-rel:not(.no-rel).open::after {
      opacity: 0;
      transition: width 20ms linear;
      width: 100%;
   }

   &:not(.open).hovering {
      &::after {
         width: 100%;
      }
   }

   &.hovering.open::after {
      // width: 100%;
      transition: width 20ms linear;
   }

   &.has-rel:not(.no-rel, .open, .no-hover):hover {
      &::after {
         transition: width 1500ms linear;
         width: 100%;
      }
   }

   a:hover {
      text-decoration: underline;
   }

   .status {
      display: flex;
      font-size: .5em;
      gap: .25rem;
      letter-spacing: 0.03em;
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;

      & > i {
         // width: 0.5em;
         // height: 0.5rem;
         display: inline-block;
         // border-radius: 2em;
         // background: orange;

         &.desc {
            // background: lime;
         }

         &.desc {
            // background: lime;
         }
      }
   }

   .close {
      background: linear-gradient(45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px)), linear-gradient(-45deg, transparent calc(50% - 1px), white 50%, transparent calc(50% + 1px));
      font-size: 1rem;
      height: 3rem;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: scale(0);
      transition: none;
      width: 3rem;
   }

   &.open {
      display: grid !important;
      // left: 50% !important;
      margin: 0 !important;
      max-height: 30em;
      max-height: 70vh;
      max-width: 60vw;
      min-height: 5rem;
      opacity: 1 !important;
      overflow-y: auto;
      // top: 50% !important;
      // transform: translate3d(-50%, -50%, 530px) rotateX(-2deg) scale(0.80);
      transition: 
         transform $openDur $cubic,
         left $openDur $cubic,
         top $openDur $cubic,
         width $openDur $cubic,
         max-height $openDur $cubic
         !important;
      width: 60vw !important;
      z-index: 3;

      &, * {
         cursor: auto !important;
         pointer-events: all;
      }

      span {
         cursor: text !important;
      }

      ::selection {
         background-color: rgb(255 255 255 / 33%);
         text-shadow: none;
      }

      .contributed.by {
         font-size: 1.25rem;
         left: calc(66.66% - 0.5rem);
         position: absolute;
         top: 1rem;

         &::before {
            content: attr(class);
            display: block;
            font-size: .75rem;
            margin-bottom: 0.5rem;
            text-transform: capitalize;
            text-transform: uppercase;
         }

         span {
            display: block;
            font-size: $s;
            margin-bottom: .25rem;
            margin-top: .25rem;
         }

         a {
            display: block;
            font-size: $s;
            margin-top: .25rem;
         }
      }

      &:not(.has-image) {
         figure {
            display: none;
         }
      }

      &.has-image {
         figure {
            // right: 1rem;
            // top: 5rem;
            filter: none;
            grid-row: 3/span 1;
            height: auto;
            max-height: 80vh;
            // padding-top: 2rem;
            transition: all $openDur, padding $openDur 320ms, filter 300ms;
            // transition: all $openDur, padding $openDur 320ms; // 160ms $lazy;
            width: 100%;
            
            figcaption {
               font-size: $s;
               margin-top: 1rem;
            }
            
            img {
               background-color: white;
               height: auto;
               // object-fit: contain;
               transition: all $openDur; // 160ms $lazy;
               // height: 100%;
            }
            
            & ~ .box-meta {
               margin-bottom: .725rem;
               transition: margin $openDur*2;
               // transition: margin $openDur 320ms;
            }
            
            .videothumb {
               height: 100%;
               overflow: hidden;
               
               img {
                  transform: scale(1.33);
               }
            }
         }
      }

      .title {
         min-height: 0;
      }

      .box-meta {
         justify-content: flex-start;
         max-width: calc(100% - 4rem);
      }

      .status {
         font-size: .725rem;
         letter-spacing: 0.03em;
         padding-top: 0.75rem;
         pointer-events: none;
         right: calc(33.33% + 3.5rem);
      }
      
      .close {
         opacity: 1;
         pointer-events: auto;
         transform: scale(1);
         transition: opacity 120ms, transform $openDur 500ms;

         &:hover {
            opacity: .5;
         }
      }
   }

   &.related {
      border-color: white !important;
      display: block !important;
      min-height: 8em;
      // transform: translate3d(-50%, -10%, 0);
      min-width: 12em;

      // &-fly {
      // transition: top 500ms, left 500ms;
      // transform: translateZ(10px);
      // margin: 0 !important;
      opacity: 1 !important;
      pointer-events: auto !important;
      transition: 
         left $flyDur $flyBezier,
         top $flyDur $flyBezier;
      z-index: 1;
      
      &[data-strength="5"] {
         // transform: scale(1) !important;
         // transform: translate3d(-50%, -10%, -100px);
         transform-origin: 0 50%;
         // transition: none !important;
         // transition: top 200ms, left 200ms, transform 200ms !important;
      }

      &[data-strength="4"] {
         // transform: translate3d(-50%, -10%, -50px);
         // transform: scale(.8) !important;
         transform-origin: 0 50%;
         // transition: none !important;
         // transition: top 200ms, left 200ms, transform 200ms !important;
      }
      
      &[data-strength="3"] {
         // transform: scale(.72) !important;
         transform-origin: 0 50%;
         // transition: none !important;
         // transition: top 200ms, left 200ms, transform 200ms !important;
      }
      
      &[data-strength="2"] {
         // transform: scale(.65) !important;
         transform-origin: 0 50%;
         // transition: none !important;
         // transition: top 200ms, left 200ms, transform 200ms !important;
      }
      
      &[data-strength="1"] {
         // transform: scale(.55) !important;
         transform-origin: 0 50%;
         // transition: none !important;
         // transition: top 200ms, left 200ms, transform 200ms !important;
      }
   }

   // &-body {
   //    display: none;
   //    // display: grid;
   //    // position: absolute;
   //    pointer-events: none;
   //    // opacity: 0;
   // }

   .box--main,
   .box--aside {
      display: none;
      line-height: 1.375;
      // max-height: 54vh;
      // overflow-y: auto;
      // pointer-events: none;
   }

   & > * {
      z-index: 1;
   }

   &:not(.open) {
      cursor: pointer;
      // margin: 0 !important;

      .title {
         margin-bottom: 1rem;
         min-height: 3rem;
      }

      .contributed.by {
         display: none;
      }
   }

   &.hovering:not(.open) {
      z-index: 2;
   }

   &:not(.open):hover {
      // transform: translate3d(0px, 0px, 0px) rotateX(-50deg) scaleY(1.5);
      text-shadow: 0 0 1em black;
      z-index: 3;

      figure {
         filter: none;
      }

      h3 {
         text-decoration: underline;
      }
   }

   h3 {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   .authors {
      @include tiny;

      margin-top: auto;
      padding-top: 1rem;
   }

   figure {
      filter: grayscale(1) contrast(0.9);
      grid-column: 2;
      grid-row: 1 / span 2;
      justify-self: flex-end;
      // position: absolute;
      max-height: 3rem;
      right: 1rem;
      // grid-row: -1;
      transition: all $openDur, padding $openDur 320ms, filter 300ms;
      width: 3rem;
      // text-align: right;
     
     img {
         background-color: white;
         display: block;
         height: 3rem;
         object-fit: cover;
         // height: 100%;
         width: 100%;
      }
     
      & + .title {
         // margin-top: 1rem;
         // margin-right: 3.5rem;
         transition: all $openDur*2;
         // min-height: 3rem;
         // margin-bottom: 1rem;
      }
   }

   .embed {
      background: #292929;
      height: 0;
      margin-bottom: 1rem;
      padding-bottom: 56.25%;
      position: relative;
      width: 100%;

      .playerthumb {
         object-fit: cover;
      }
      
      .playerthumb,
      iframe {
         height: 100%;
         left: 0;
         position: absolute;
         top: 0;
         width: 100%;
      }

      iframe {
         z-index: 1;
      }
   }

   .box--main {
      grid-column: 1;
      grid-row: 3/span 3;
      letter-spacing: 0.01em;
      line-height: 1.5;
      padding-right: 5%;
   }

   &.has-image figure ~ .box--aside {
      border-top: 1px solid;
      margin-top: 1rem;
      padding-top: 1.5rem;
   }


   .box--aside,
   figure {
      &:has(~ .contributed.by) {
         border-top: 1px solid;
         margin-top: 2rem;
         padding-top: 2rem;
      }
   }

   .box--aside {
      font-size: $s;
      grid-column: 2;
      padding-top: .25rem;
      
      &:empty {
         border-top: 0;
      }
      
      span.fetchurl {
         display: inline-block;
         max-width: 100%;
         overflow: hidden;
         text-overflow: ellipsis;
         transition: all 300ms;
         vertical-align: middle;
         white-space: nowrap;

         &:hover {
            text-decoration: underline;
         }
      }

      .load {
         position: relative;

         &::after {
            animation: spin 1.4s infinite;
            border: 4px dotted;
            border-radius: 10em;
            box-sizing: border-box;
            content: "";
            display: block;
            height: 1rem;
            left: 0;
            position: absolute;
            top: 0;
            width: 1rem;
         }

         span.fetchurl {
            text-indent: 2em;
         }
      }

      .rm {
         display: none !important;
      }

      & > div {
         &:not(:last-child) {
            margin-bottom: 1rem;
         }
         
         &::before {
            content: attr(class);
            display: block;
            margin-bottom: .5em;
            text-transform: uppercase;
         }

         & > * {
            display: list-item;
            list-style-type: disc;
            margin-bottom: .5em;
            margin-left: 1em;
         }
      }
      
      .links {
         a {
            margin-left: 0 !important;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
               content: "•";
               padding-right: .5em;
            }
         }
      }
   }

   .box-meta {
      @include tiny;

      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      // flex-direction: column;
      justify-content: space-between;
      margin-top: 0.5rem;
      text-align: right;
      white-space: nowrap;
   }
}
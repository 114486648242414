@font-face {
	font-display: swap;
	font-family: diatype;
	font-style: normal;
	font-weight: 400;
	src:  local(''),
		url('../font/ABCDiatype-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('../font/ABCDiatype-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$sans: diatype, sans-serif;